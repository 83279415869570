<template>
 <div>
    <v-row  no-gutters>
           <v-col cols="12"  class="pa-4 text-center">
               <div class="text-primary text-h3">{{$money(balance)}}</div>
                <small>CURRENT BALANCE</small>
           
          </v-col>
        <v-col cols="12" class="pa-2">
            <va-load-card 
             v-if="countdown"
              color="secondary"
              icon="mdi-billiard"
              :label="$moment(RESULT.date).format('MMM DD, YYYY') + ' ' + RESULT.time + ' RESULT'"
              :data="RESULT.combination"
              sup=""
            />
             <va-main-card 
             v-else
              color="secondary"
              icon="mdi-billiard"
              :label="$moment(RESULT.date).format('MMM DD, YYYY') + ' ' + RESULT.time + ' RESULT'"
              :data="RESULT.combination"
              sup=""
            />
           
        </v-col>
         <v-col cols="12" class="pa-2" v-if="watch==true">
           <v-btn color="success" x-large @click="openDraw()" block> <v-icon color="error" left>mdi-play-circle</v-icon> watch Draw</v-btn>
        </v-col>
        <v-col cols="12" class="pl-2 pr-2" v-if="nodraw==true">
                  <v-alert
                  dense
                  outlined
                  type="info"
                  >
                    No draws at the moment. Try again later!
                  </v-alert>
        </v-col>
         <v-col cols="12" class="pl-2 pr-2" v-if="ongoing==true">
                  <v-alert
                  dense
                  outlined
                  type="warning"
                  >
                    Ongoing Draw. Stay tuned for next draw!
                  </v-alert>
        </v-col>
         <v-col cols="6" class="pa-2">
           <va-stat-card 
              color="accent"
              icon="mdi-billiard"
              label="Today's Transactions"
              :data="item_count_total"
              sup=""
            />
        </v-col>
         <v-col cols="6" class="pa-2">
           <v-card color="gold" dark>
             <v-card-text class="text-center pa-2">
              Next Draw 
              <div class="text-h4"> {{showcountdown?COUNTDOWNLABEL:"--:--"}}</div>
             </v-card-text>
           </v-card>
        </v-col>
        <v-col cols="6"  class="pa-2 mt-5">
            <v-btn  dark  color="gold"  block @click="$router.push('/results')"><v-icon  left>mdi-clipboard-list-outline</v-icon> RESULTS</v-btn>
        </v-col>
          <v-col cols="6"  class="pa-2 mt-5">
            <v-btn   @click="how=true" block>HOW TO PLAY</v-btn>
          
        </v-col>

        <v-col cols="12"  class="pa-2 mt-5">
              <v-btn  color="#DF4737"  dark x-large  block @click="$router.push('/play')"><v-icon  left>mdi-cash-register</v-icon> PLAY</v-btn>
        </v-col>
     
    </v-row>
    <va-howto :show="how" @DialogEvent="hEvent"/>
  </div>
</template>
<script>
import { API_MIXINS } from '@/mixins/api_mixins.js'
import {
  mapMutations
} from 'vuex'
  export default {
    mixins: [API_MIXINS],
    data: () => ({
      CHECKINTERVAL: null,
      watch: false,
      DRAWINTERVAL: null,
      next_draw:"",
      how:false,
      database: null,
      nodraw: false,
      ongoing: false,
      result: {},
      countdown: false,
       showcountdown: true,
       countNum: "00:00"
    }),
    created() {
      this.PING()
         this.database = window.firebase.database();
        var resultListener = this.database.ref('result');
          resultListener.on('value', (snapshot) =>{
            this.result = snapshot.val()
             this.RunProcess() 
          });
        
    },
     mounted(){
         this.setDrawer(false)
       this.setLoggedIn(true)
        if (this.user == null) {
          this.$router.push('/')
        }else {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.user.token
         // console.log(this.$moment(1607758440000).format("YYYY-MM-DD HH:mm:ss"))
            this.setWatchDraw(false)
            this.setData()
            if(!sessionStorage.machine) {
                console.log("please load the machine")
                sessionStorage.machine="init"
                this.setOpenMachine(true)
               
            } else {
              console.log("Done load the machine")
            }
        }
        
    },
    beforeDestroy(){
       if(this.CHECKINTERVAL != null) { clearInterval(this.CHECKINTERVAL)}
       return true
    },
    computed:{
      balance(){
        return  this.$store.state.balance.balance
      },
      isOnline(){
        return this.$store.state.is_online
      },
      user() {
        if(localStorage.user){
            return JSON.parse(localStorage.user)
        }
        return null
      },
      RESULT() {
        return this.$store.state.results.length>0?this.$store.state.results[0]:{}
      },
      item_count() {
        return this.$store.state.tickets.length.toString()
      },
      item_count_total() {
          var count = this.$store.state.tickets.reduce((res,item)=>res += this.$IsNum(item.amount), 0)
          return count.toString()
      },
      OPEN_DRAWS() {
       return this.$store.state.open_draws
      },
      COUNTDOWNLABEL() {
        return this.countNum == 0? "DRAW":this.countNum
      }
    },
    methods: {
      ...mapMutations(['setDrawer', 'setAlertColor', 'setAlertText', 'setLoggedIn', 'setOverlayLoading', 'setOverlayLoadingText', 'setOpenMachine','setWatchDraw']),
      RunProcess(){
        this.setWatchDraw(false)
         this.watch = false
          this.nodraw = false
           this.ongoing = false
        if(this.result.status == "close") {
          this.VA_ALERT("warning", "Betting is now closed!")
             this.watch = true
             this.setWatchDraw(true)
        } else if(this.result.status == "ready") {
           this.watch = true
           this.setWatchDraw(true)
        } else if(this.result.status == "open") {
           this.watch = false
           this.setWatchDraw(false)
            this.showcountdown = true
                this.VA_ALERT("info", "Betting is open for next draw!")
                  this.setOpenMachine(false)
                  this.setData()
                this.startCountDown()
        } else if(this.result.status == "fordraw") {
          this.showcountdown = false
             this.watch = false
             this.setWatchDraw(false)
        }  else if(this.result.status == "nodraw") {
                  this.watch = false
                  this.nodraw = true
                  this.setWatchDraw(false)
                  this.showcountdown = true
                  if(sessionStorage.machine=="loaded") {
                    this.setOpenMachine(false)
                  }
                  //  if(sessionStorage.machine) {
                  // this.setOpenMachine(false)
                  //  }
                  this.setData()
                  
        } else if(this.result.status == "ongoing") {
                this.ongoing = true
                  
        }
      },
      hEvent(){
        this.how= false
      },
      openDraw(){
        this.setOpenMachine(true)
         this.countdown = false
      },
        startCountDown(){
         if(this.OPEN_DRAWS.length !=0) {
          this.CHECKINTERVAL = setInterval(()=>{
              var a = this.$moment(this.OPEN_DRAWS[0].draw_date_time)
                var b = this.$moment()
                var cd = a.diff(b)
                var duration = this.$moment.duration(cd)
              this.countNum = this.$moment("2015-01-01").startOf('day').seconds(Math.trunc(duration.asSeconds())).format('mm:ss')
              
                if(Math.trunc(duration.asSeconds()) == 0 ) {
                  this.showcountdown = false
                    this.countNum = 0
                    this.countdown = false
                    clearInterval(this.CHECKINTERVAL)
                }
            },1000)
         } 
            
        },
        setData() {
          this.CHECK_BALANCE(this.user.id)
          var param = {}
          param.account = this.user.id
          param.method = "per_account"
          param.from_date =  this.$moment().format("YYYY-MM-DD")
          this.GET_TICKETS(param)
          this.GET_RESULTS(param)
          this.GET_OPEN_GAMES()
          setTimeout(()=>{this.startCountDown()}, 800)
      }
    },
  }
</script>